import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import ImageUploadBase from '@emcasa/image-upload/ImageUpload/index.jsx';
import ImageUpload from '@emcasa/image-upload/html5';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "imageupload"
    }}>{`ImageUpload`}</h1>
    <Props of={ImageUploadBase} mdxType="Props" />
    <Playground __position={1} __code={'<ImageUpload\n  showInactive\n  images={[\n    { id: 1, filename: \'https://bit.ly/2VQOSeF\', isActive: true },\n    { id: 2, filename: \'https://bit.ly/30IXSBs\', isActive: true },\n    { id: 3, filename: \'https://bit.ly/2JwCtWu\', isActive: true },\n    { id: 4, filename: \'https://bit.ly/2lPi848\', isActive: false },\n  ]}\n  onUpload={img => {\n    console.log(\'upload\', img)\n    return new Promise(resolve => setTimeout(resolve, 2500))\n  }}\n  onChangePosition={img => console.log(\'move\', img)}\n  onDeactivate={img => console.log(\'deactivate\', img)}\n  onActivate={img => console.log(\'activate\', img)}\n  onClick={img => console.log(\'click\', img)}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      ImageUploadBase,
      ImageUpload
    }} style={{
      minHeight: "250px"
    }} mdxType="Playground">
  <ImageUpload showInactive images={[{
        id: 1,
        filename: 'https://bit.ly/2VQOSeF',
        isActive: true
      }, {
        id: 2,
        filename: 'https://bit.ly/30IXSBs',
        isActive: true
      }, {
        id: 3,
        filename: 'https://bit.ly/2JwCtWu',
        isActive: true
      }, {
        id: 4,
        filename: 'https://bit.ly/2lPi848',
        isActive: false
      }]} onUpload={img => {
        console.log('upload', img);
        return new Promise(resolve => setTimeout(resolve, 2500));
      }} onChangePosition={img => console.log('move', img)} onDeactivate={img => console.log('deactivate', img)} onActivate={img => console.log('activate', img)} onClick={img => console.log('click', img)} mdxType="ImageUpload" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      